<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.9 14.83">
    <polyline
      points="6.45 3.78 6.45 7.42 8.97 8.87"
      style="fill: none; stroke: currentColor; stroke-linecap: round; stroke-linejoin: round"
    />
    <path
      d="m6.45,13.24c-3.22,0-5.82-2.61-5.82-5.82S3.24,1.59,6.45,1.59s5.82,2.61,5.82,5.82c0,3.21-2.61,5.82-5.82,5.82Z"
      style="fill: none; stroke: currentColor; stroke-linecap: round; stroke-linejoin: round"
    />
  </svg>
</template>

<style scoped></style>
